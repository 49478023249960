<template>
  <div class="sidebar-left">
    <div class="sidebar">
      <div class="sidebar-content email-app-sidebar">
        <div
          class="email-app-menu"
        >

          <div
            v-if="permCreateProposal === true && showListVisits === false && showDetail == false && [1, 4, 7].includes(Number(currentUser.id_role))"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="primary"
              block
              :disabled="$store.getters['crm_proposals/loadingListview']"
              @click="createNewProposals"
            >
              {{ $t('Nova proposta') }}
            </b-button>
          </div>

          <div
            v-if="showForm === true"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              block
              @click="$emit('save-new-proposal')"
            >
              {{ $t('Submeter') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-danger"
              block
              @click="$emit('cancel-new-proposal')"
            >
              {{ $t('Cancelar') }}
            </b-button>
          </div>

          <div
            v-if="showDetail == true"
            class="form-group-compose text-center compose-btn"
          >
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-primary"
              block
              @click="getActivityProposal"
            >
              {{ $t('Registo de atividade') }}
            </b-button>
            <b-button
              v-if="[2, 6, 13].includes(Number(proposalDetail.proposal.sw130s12)) && (Number(proposalDetail.listing.sw012s330) === 1 || Number(proposalDetail.listing.sw012s64) === 1)
                && ![1, 5, 6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role)) && typeof proposalDetail.lastEntry !== 'undefined' && proposalDetail.lastEntry.length === 0 && proposalDetail.proposalDisable === false"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="counterProposal(0)"
            >
              {{ $t('Retomar negociação') }}
            </b-button>
            <b-button
              v-if="[2, 6, 13].includes(Number(proposalDetail.proposal.sw130s12)) && Number(proposalDetail.listing.sw012s330) !== 1 && Number(proposalDetail.listing.sw012s64) !== 1
                && ![1, 5, 6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role)) && proposalDetail.proposalDisable === false"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="counterProposal(1)"
            >
              {{ $t('Retomar negociação') }}
            </b-button>
            <b-button
              v-if="[2, 6, 13].includes(Number(proposalDetail.proposal.sw130s12)) && typeof proposalDetail.lastEntry !== 'undefined' && proposalDetail.lastEntry.length > 0 && ![1, 5, 6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role)) && proposalDetail.proposalDisable === false"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="counterProposal(2)"
            >
              {{ $t('Retomar negociação') }}
            </b-button>
            <b-button
              v-if="[1, 4, 14].includes(Number(proposalDetail.proposal.sw130s12)) && ![1, 5, 9, 10, 13].includes(Number(currentUser.id_role)) && Number(proposalDetail.iWhoami) === 4
                && (Number(proposalDetail.listing.sw012s330) === 2 || Number(proposalDetail.listing.sw012s64) === 2) && (configApp.bUseProcessosVenda === false || configApp.bUseProcessosVendaCancelReserva === false || ![1, 4, 14].includes(Number(proposalDetail.proposal.sw130s12))
                  || Number(proposalDetail.salesProcessStatus) === 3)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="cancelReservation(true)"
            >
              {{ $t('Cancelar reserva') }}
            </b-button>
            <b-button
              v-if="[1, 4, 14].includes(Number(proposalDetail.proposal.sw130s12)) && ![1, 5, 9, 10, 13].includes(Number(currentUser.id_role)) && Number(proposalDetail.iWhoami) === 4
                && (configApp.bUseProcessosVenda === false || configApp.bUseProcessosVendaCancelReserva === false || ![1, 4, 14].includes(Number(proposalDetail.proposal.sw130s12)) || Number(proposalDetail.salesProcessStatus) === 3)"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="cancelReservation(false)"
            >
              {{ $t('Cancelar reserva') }}
            </b-button>
            <b-button
              v-if="((typeof proposalDetail.proposal.sw130s12 === 'undefined' || proposalDetail.proposal.sw130s12 === null || proposalDetail.proposal.sw130s12 === '') || ![1, 14, 9, 11].includes(Number(proposalDetail.proposal.sw130s12)))
                && ![1, 5, 6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role)) && Number(proposalDetail.iWhoami) === 1 && [1, 4, 14].includes(Number(proposalDetail.proposal.sw130s12))"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="cancelProposal(false)"
            >
              {{ $t('Cancelar proposta') }}
            </b-button>
            <b-button
              v-if="((typeof proposalDetail.proposal.sw130s12 === 'undefined' || proposalDetail.proposal.sw130s12 === null || proposalDetail.proposal.sw130s12 === '') || ![1, 14, 9, 11].includes(Number(proposalDetail.proposal.sw130s12)))
                && ![1, 5, 6, 9, 10, 13, 11, 12].includes(Number(currentUser.id_role)) && Number(proposalDetail.iWhoami) === 1 && ![1, 4, 14].includes(Number(proposalDetail.proposal.sw130s12))"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="cancelProposal(true)"
            >
              {{ $t('Cancelar proposta') }}
            </b-button>
            <b-button
              v-if="[4, 7].includes(Number(currentUser.id_role)) && [15].includes(Number(proposalDetail.proposal.sw130s12)) && Number(proposalDetail.notHaveUploadFiles) !== 1"
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-secondary"
              block
              @click="uploadDocuments()"
            >
              {{ $t('Carregar documentos') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              block
              @click="closeModalEvent"
            >
              {{ $t('Fechar') }}
            </b-button>
          </div>

          <vue-perfect-scrollbar
            v-if="filterData.load.value===true"
            ref="filterScroll"
            class="sidebar-menu-list scroll-area"
            :settings="configSettingsScroll"
          >

            <!-- Separadores -->
            <div
              v-if="filterData.tabsListview.length > 0 && showForm === false && showDetail == false"
              class="mt-2 px-2 d-flex justify-content-between"
            >
              <h6 class="section-label mb-1">
                {{ $t('Separador') }}
              </h6>
            </div>
            <b-list-group
              v-if="showListVisits === false && showDetail == false && showForm === false && filterData.tabsListview.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="oTab in filterData.tabsListview"
                :key="oTab.name"
                :style="{cursor: 'pointer' }"
                :active="((tabSelectFilter === oTab.name) ? true : false)"
                @click.stop.prevent="listviewFilterTab(oTab.name);$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ oTab.text }}</span>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              v-if="showListVisits === true && showForm === false && filterData.tabsListviewVisits.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="oTab in filterData.tabsListviewVisits"
                :key="oTab.name"
                :style="{cursor: 'pointer' }"
                :active="((tabSelectFilterVisits === oTab.name) ? true : false)"
                @click.stop.prevent="listviewFilterTabVisits(oTab.name);$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ oTab.text }}</span>
              </b-list-group-item>
            </b-list-group>

            <!-- Estado :: Propostas -->
            <h6
              v-if="showListVisits === false && showDetail == false"
              class="section-label mt-3 mb-1 px-2"
            >
              {{ $t('Estado') }}
            </h6>
            <b-list-group
              v-if="showListVisits === false && showDetail == false && tabSelectFilter === 'received' && filterData.estadoReceived.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="row in filterData.estadoReceived"
                :key="row.id"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadoReceived.value === Number(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadoPropostasReceived': Number(row.id) });$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ row.desc }}</span>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              v-if="showListVisits === false && showDetail == false && tabSelectFilter === 'sent' && filterData.estadoSent.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="row in filterData.estadoSent"
                :key="row.id"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadoSent.value === Number(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadoPropostasSent': Number(row.id) });$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ row.desc }}</span>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              v-if="showListVisits === false && showDetail == false && tabSelectFilter === 'approve' && filterData.estadoApprove.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="row in filterData.estadoApprove"
                :key="row.id"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadoApprove.value === Number(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadoPropostasApprove': Number(row.id) });$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ row.desc }}</span>
              </b-list-group-item>
            </b-list-group>
            <b-list-group
              v-if="showListVisits === false && showDetail == false && tabSelectFilter === 'reserve' && filterData.estadoReserve.length > 0"
              class="list-group-messages"
            >
              <b-list-group-item
                v-for="row in filterData.estadoReserve"
                :key="row.id"
                :style="{cursor: 'pointer' }"
                :active="((filterSelected.estadoReserve.value === Number(row.id)) ? true : false)"
                @click.stop.prevent="listviewFilterGeral({'filterSelectedEstadoPropostasReserve': Number(row.id) });$emit('close-left-sidebar')"
              >
                <span class="align-text-bottom line-height-1">{{ row.desc }}</span>
              </b-list-group-item>
            </b-list-group>

          </vue-perfect-scrollbar>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import {
  BButton, BListGroup, BListGroupItem,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters } from 'vuex'
import { sizeIconsAction } from '@core-custom/mixins/geral'
import { configSettingsScroll } from '@core-custom/utils/ui'
import checkNested from 'check-nested'

export default {
  directives: {
    Ripple,
  },
  components: {
    BButton,
    BListGroup,
    BListGroupItem,
    VuePerfectScrollbar,
  },
  mixins: [sizeIconsAction],
  props: {
    showMsgErrorRequest: {
      type: Function,
      required: true,
    },
    cancelReservation: {
      type: Function,
      required: true,
    },
    cancelProposal: {
      type: Function,
      required: true,
    },
    counterProposal: {
      type: Function,
      required: true,
    },
    uploadDocuments: {
      type: Function,
      required: true,
    },
    getActivityProposal: {
      type: Function,
      required: true,
    },
    backToVisitas: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
    }
  },
  computed: {
    ...mapGetters('crm_proposals', ['filterData', 'filterSelected', 'tabSelectFilter', 'tabSelectFilterVisits', 'showForm', 'showDetail', 'proposalDetail']),
    ...mapGetters('auth', ['currentUser']),
    showListVisits: {
      get() { return this.$store.getters['crm_proposals/showListVisits'] },
      set(newValue) {
        this.$store.dispatch('crm_proposals/setShowListVisits', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    showDetail: {
      get() { return this.$store.getters['crm_proposals/showDetail'] },
      async set(newValue) {
        await this.$store.dispatch('crm_proposals_events/clearSWkeepalive', { sw130s01: btoa(this.proposalDetail.proposal.sw130s01) }).catch(error => {
          this.showMsgErrorRequest(error)
        })

        await this.$store.dispatch('crm_proposals/setShowDetail', newValue).catch(error => {
          this.showMsgErrorRequest(error)
        })
      },
    },
    permCreateProposal() {
      const oCurrentUser = this.$store.getters['auth/currentUser']

      if (oCurrentUser !== null && oCurrentUser !== undefined && checkNested(oCurrentUser, 'extras.basic') && oCurrentUser.extras.basic === 1) {
        return true
      }

      if (oCurrentUser !== null && oCurrentUser !== undefined && 'extras' in oCurrentUser && 'perm' in oCurrentUser.extras
        && (Object.values(oCurrentUser.extras.perm).includes('pode_marcar_visitas_leads') || Object.values(oCurrentUser.extras.perm).includes('pode_marcar_visitas_leads_edita') || Object.values(oCurrentUser.extras.perm).includes('efetua_propostas_outros_membros'))) {
        return true
      }

      return false
    },
  },
  methods: {
    fetchListView() {
      this.$store.dispatch('crm_proposals/fetchListView').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async listviewFilterTab(tabSel) {
      await this.$store.dispatch('crm_proposals/getDataFiltersListview', { tab: tabSel }).catch(error => {
        this.showMsgErrorRequest(error)
      })

      await this.$store.dispatch('crm_proposals/listviewFilterTab', tabSel).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    async listviewFilterTabVisits(tab) {
      await this.$store.dispatch('crm_proposals/listviewFilterTabVisits', tab).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    listviewFilterGeral(params) {
      this.$store.dispatch('crm_proposals/listviewFilterGeral', params).catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    resetListviewFilterGeral() {
      this.$store.dispatch('crm_proposals/resetListviewFilterGeral').catch(error => {
        this.showMsgErrorRequest(error)
      })
    },
    createNewProposals() {
      this.showListVisits = true
      this.$emit('close-left-sidebar')
    },
    closeModalEvent() {
      if (this.backToVisitas === true) {
        this.$router.push({ name: 'crm-visitas-listview' })
      } else {
        this.showDetail = false
        this.$emit('close-left-sidebar')
      }
    },
  },
  setup() {
    return {
      configSettingsScroll,
    }
  },
}
</script>

<style lang="scss" scoped>
.email-application .content-area-wrapper .sidebar .email-app-sidebar .email-app-menu.sws-not-button-create .sidebar-menu-list{
  height: calc(100%);
}
</style>
